import { useEffect } from "react";
import Swal from "sweetalert2";
import { auth } from "./firebase";

const InactivityHandler = ({ isLoggedIn }) => {
    useEffect(() => {
        if (!isLoggedIn) return;

        let activityTimer;
        const fixedTimeout = 24 * 60 * 60 * 1000;
        const activityTimeout = 3 * 60 * 60 * 1000;

        let lastExpiryLog = Date.now() + fixedTimeout;
        let sessionExpired = false;

        const formatTime = (timestamp) => {
            return new Date(timestamp).toLocaleString("en-US", {
                hour12: false,
                timeZoneName: "short",
            });
        };

        localStorage.setItem("fixedSessionExpiry", lastExpiryLog.toString());

        const handleLogout = () => {
            if (sessionExpired) return;
            sessionExpired = true;
            clearTimeout(activityTimer);

            Swal.fire({
                title: "Session Expired",
                html: '<div style="text-align: center; font-size: 18px;">Click OK to login</div>',
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
                allowOutsideClick: false,
            }).then(() => {
                if (auth?.currentUser) {
                    auth.signOut().then(() => {
                        localStorage.clear();
                        window.location.href = "/login";
                    });
                } else {
                    localStorage.clear();
                    window.location.href = "/login";
                }
            });
        };

        const checkFixedTimeout = () => {
            const fixedSessionExpiry = localStorage.getItem("fixedSessionExpiry");
            if (fixedSessionExpiry && Date.now() > parseInt(fixedSessionExpiry, 10)) {
                handleLogout();
            }
        };

        const resetActivityTimer = () => {
            if (sessionExpired) return;
            const newExpiryTime = Date.now() + activityTimeout;
            lastExpiryLog = newExpiryTime;
            clearTimeout(activityTimer);
            activityTimer = setTimeout(handleLogout, activityTimeout);
        };

        const activityEvents = ["mousemove", "keydown", "click", "scroll", "touchstart"];
        activityEvents.forEach((event) => {
            window.addEventListener(event, resetActivityTimer);
        });

        resetActivityTimer();
        const fixedInterval = setInterval(checkFixedTimeout, 1000);

        return () => {
            clearTimeout(activityTimer);
            clearInterval(fixedInterval);
            activityEvents.forEach((event) => {
                window.removeEventListener(event, resetActivityTimer);
            });
        };
    }, [isLoggedIn]);

    return null;
};

export default InactivityHandler;
