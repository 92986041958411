import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import MainRouter from './MainRouter';
import InactivityHandler from './Common/InactivityHandler';
import 'bootstrap/dist/js/bootstrap.bundle';
import ReactGA from "react-ga4";
import { auth } from './Common/firebase';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  ReactGA.initialize("G-W56NGKZVKC");
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <MainRouter />
        <InactivityHandler isLoggedIn={isLoggedIn} />
      </Provider>
    </div>
  );
}

export default App;
